import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, ViewChild, OnInit, OnChanges, OnDestroy, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { NbPopoverDirective } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbilityService } from '@/app/services/ability.service';
import { LOAD_DEMAND_ACTUAL_X_DATA, LOAD_DEMAND_MULTI_ACTUAL_X_DATA } from '@/store/pages/demand-planning/demand-planning.actions';
import { LOAD_SIT_ACTUAL_X_DATA } from '@/store/pages/sit/sit.actions';
import { TOGGLE_COLLAPSE_EVENT_LIST } from '@/store/event/event.actions';
import { select_displayingScenarios } from '@/store/scenario/scenario.selectors';
import { select_params_loadDemandChartData } from '@/store/pages/demand-planning/demand-planning.selectors';
import { MAX_SCENARIO_DISPLAY_COUNT } from '@/app/pages/explorer/planning-explorer/planning-explorer.utils';
import { Plan, PlanFlag } from '../../../@core/interfaces/business/plan';
import { DatasetEntry, DatasetTreeNode, Scenario, ScenarioFlag, ScenarioWithTaskStatus } from '../../../@core/interfaces/business/scenario';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';

@Component({
  selector: 'cel-planning-scenarios',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./planning-scenarios.component.scss'],
  templateUrl: './planning-scenarios.component.html',
})
export class PlanningScenariosComponent implements OnInit, OnChanges, OnDestroy {
  /** Reference to the popover element in html. */
  @ViewChild(NbPopoverDirective) popover?: NbPopoverDirective;

  @Input() plans?: Plan[];
  @Input() plan?: Plan;
  @Input() unselectedScenarios: Scenario[] = [];
  treeData: DatasetTreeNode<DatasetEntry>[] = [];
  @Input() scenarios: ScenarioWithTaskStatus[] = [];
  @Input() listScenarios: Scenario[] = [];

  @Input() primary?: Scenario;
  @Input() highlighted?: Scenario;
  @Input() editMode?: boolean;

  // selectedScenarios = this.store.select(select_displayingScenariosWithTaskStatus);

  /** Fires if the user want the scenario to be a primary scenario. */
  @Output() setPrimary = new EventEmitter<Scenario>();
  /** Fires if the user want the scenario to be a highlighted scenario. */
  @Output() setHighlighted = new EventEmitter<Scenario>();

  /** Fires if the user selected create a new scenario. */
  @Output() create = new EventEmitter<Scenario>();
  /** Fires if user selects a scenario on the add scenario popup. */
  @Output() add = new EventEmitter<Scenario>();
  /** Fires if user clicks on the scenario edit button. */
  @Output() edit = new EventEmitter<Scenario>();
  /** Fires if user clicks on the scenario remove button. */
  @Output() hide = new EventEmitter<Scenario>();
  @Output() set = new EventEmitter<Scenario[]>();

  @Output() delete = new EventEmitter<Scenario>();
  @Output() clone = new EventEmitter<Scenario>();
  @Output() download = new EventEmitter<{ scenario: Scenario, plan: Plan, downloadType: string, dateRange: SimpleDateRange }>();

  @Output() changeDisplayedScenarios = new EventEmitter<Scenario[]>();

  displayingScenarios$ = this.store.select(select_displayingScenarios);
  params$ = this.store.select(select_params_loadDemandChartData);
  // selectedPlanId$ = this.store.select(select_selectedPlanId);

  private firstRun: boolean = true;

  canManageScenario?: boolean;

  previousPlan = '';

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly abilityService: AbilityService,
  ) { }

  ngOnInit(): void {
    const ability = this.abilityService.getAbility();

    this.canManageScenario = ability.can('manage', 'Scenario');

    combineLatest([this.displayingScenarios$, this.params$]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([displayingScenarios, params]) => {
      if (displayingScenarios?.length > 0 && params && this.firstRun) {
        this.store.dispatch(LOAD_DEMAND_MULTI_ACTUAL_X_DATA());
        this.firstRun = false;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.unselectedScenarios && changes.unselectedScenarios.currentValue) {
      this.treeData = this.convertToTreeData(this.unselectedScenarios);
    }

    if ((changes.plans && changes.plans.currentValue) || (changes.plan && changes.plan.currentValue)) {
      this.previousPlan = this.plans?.find(plan => plan.id === this.plan?.previousPlanCycle)?.name || 'Actual';
    }
  }

  private convertToTreeData(scenarios) {
    const treeData: DatasetTreeNode<any>[] = [
      { data: { name: 'Scenarios' }, children: [] },
      { data: { name: 'Actual' }, children: [] },
      { data: { name: 'Other Plans' }, children: [] },
    ];

    scenarios.forEach(s => {
      if (treeData.length >= 3) {
        if (s.id && s.id.length === 24 && s.planRef === this.plan?.id) {
          treeData[0]?.children?.push({ data: { name: s.name, dataset: s } });
        } else if (s.flags && s.flags.includes('actual')) {
          treeData[1]?.children?.push({ data: { name: s.name, dataset: s } });
        } else {
          treeData[2]?.children?.push({ data: { name: s.name, dataset: s } });
        }
      }
    });

    return treeData;
  }

  isPrimaryScenario(scenarioId: string) {
    return this.plan?.primaryScenario === scenarioId;
  }

  isMaxDisplayedCountReached(): boolean {
    return (this.scenarios?.length || 0) >= MAX_SCENARIO_DISPLAY_COUNT;
  }

  addScenario(scenario: Scenario) {
    if (!scenario) {
      return;
    }

    const id = scenario?.id;
    if (id.includes("actual-")) {
      const offsetStr = id.split("-")[1];
      const offset = parseInt(offsetStr, 10);

      if (!isNaN(offset) && [1, 2, 3, 4, 5].includes(offset)) {
        this.store.dispatch(LOAD_DEMAND_ACTUAL_X_DATA({ offset }));
        this.store.dispatch(LOAD_SIT_ACTUAL_X_DATA({ offset }));
      }
    }

    this.add.emit(scenario);
    this.popover?.hide();
  }

  createScenario() {
    this.create.emit();
    this.popover?.hide();
    this.store.dispatch(TOGGLE_COLLAPSE_EVENT_LIST({ isCollapsed: false }));
  }

  /** Disable any kind of editing if currently displayed plan is for "Actual". */
  get readonly() {
    const flags = this.plan?.flags || [];
    return flags.includes(PlanFlag.ACTUAL);
  }

  drop(event: CdkDragDrop<string[]>) {
    // check if selected_Scenario > select_displayingWithStatus do
    moveItemInArray(this.scenarios, event.previousIndex, event.currentIndex);
    this.changeDisplayedScenarios.emit(this.scenarios);
  }

  addAndEdit(scenario: Scenario) {
    this.add.next(scenario);
    // wait for previous ng change detector to finish
    setTimeout(() => {
      this.edit.next(scenario);
    }, 1000);
  }

  getPlan(planRef): Plan | undefined {
    return this.plans?.find((p) => p.id === planRef)
  }

  isScenarioDeletable(scenario?: Scenario): boolean {
    const isNonDeletableScenario =
      scenario?.flags?.includes(ScenarioFlag.ACTUAL) ||
      scenario?.flags?.includes(ScenarioFlag.COMMITTED) ||
      scenario?.id === ScenarioFlag.CURRENT ||
      scenario?.id === ScenarioFlag.BASE_DEMAND;

    const isForecastBase =
      scenario?.flags?.includes(ScenarioFlag.FORECAST_BASE);

    return !(isNonDeletableScenario || isForecastBase);
  }

  isScenarioEditable(scenario?: Scenario): boolean {
    const isActualX = scenario?.id?.includes('actual-');

    const isActualNotInActualPlan =
      scenario?.flags?.includes(ScenarioFlag.ACTUAL) &&
      !this.plan?.flags?.includes(PlanFlag.ACTUAL);

    const isCommittedOrBaseDemand =
      scenario?.flags?.includes(ScenarioFlag.COMMITTED) ||
      scenario?.id === ScenarioFlag.CURRENT ||
      scenario?.id === ScenarioFlag.BASE_DEMAND;

    const isForecastBase =
      scenario?.flags?.includes(ScenarioFlag.FORECAST_BASE);

    return !(isActualX || isActualNotInActualPlan || isCommittedOrBaseDemand || isForecastBase);
  }
}
